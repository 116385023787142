import $ from "jquery";
import 'wcolpick';

export default function () {

  $(".color-picker").each(function (index) {
    initCP($(this));
  })

  function initCP($elem) {
    const item = $elem.data("item");
    if (item !== undefined) {
      const color = $.wcolpick.decodeFromCSS($elem.val())

      $elem.loads({
        flat: false,
        layout: 'full',
        compactLayout: false,
        border: 2,
        color: color,
        onBeforeShow: function (colorDiv, el, overridePos) {
          $(colorDiv).setColor(color, true);
        },
        onSubmit: function (ev) {
          const rgba = $(ev.el).getColor("rgba", true);
          $(ev.el).css('border-color', $.wcolpick.encodeToCSS(rgba));
          $(ev.el).val($.wcolpick.encodeToCSS(rgba));
          $(ev.el).hides();
        },
        onHide: function (ev) {
          const rgba = $(ev.el).getColor("rgba", true);
          $(ev.el).setColor(rgba, false);
          $.ajax({
            url: wp.ajaxurl,
            method: 'POST',
            data: {
              action: 'design_save_css',
              rgba: rgba,
              item: item,
            },
            error: function () {
              M.toast({
                html: 'Sorry, the system is experiencing difficulties',
                classes: 'error-toast',
              });
            },
            complete: function () {
              const $previewCard = $('#campaign-preview-card iframe');
              if ($previewCard.length) {
                $previewCard[0].contentWindow.location.reload(true);
              }
            }
          });
        }
      });
    }
  }

}
