import $ from "jquery";
import removeFieldInit from "../utils/removeFieldInit";

function initOptinsList () {
  $('.ml-add-selector').on('select2:select', e => {
    const sfId = e.params.data.id;
    const name = e.params.data.text;
    const artist = e.params.data.element.attributes[1].value;
    const $elem = $(e.currentTarget);
    const $newOptin = $(`#${$elem.data("templateid")} tr`).clone();
    const newOptinfId = `${$elem.data("mltype")}_${sfId}`;
    const is_brazil = $('#admin_options_territory').val() === '17740';
    let newsletter = `I'd like to subscribe to the ${artist} newsletter`;
    if ( is_brazil ) {
      newsletter = 'Autorizo a utilização de meus dados pessoais (nome, e-mail e telefone) pela Sony Music para o envio de novidades e campanhas de mídia relacionadas aos projetos e artistas nacionais e internacionais do Cast Sony Music, seja no Brasil ou exterior, via redes sociais, Google, SMS e aplicativos de mensagens. Confirmo ainda que tenho/sou maior de 18 anos. Essa assinatura poderá ser cancelada a qualquer momento.';
    }

    $newOptin.find('span.title').html(name);
    $newOptin.find(".fr-box").remove();
    $newOptin.find('textarea.mailing-list-name-field')
      .html(newsletter).change()
      .val(newsletter).change()
      .attr('id', `mailing-list-name-${newOptinfId}`)
      .attr('name', `mailing-list-name-${newOptinfId}`).unbind()
      .froalaEditor({
      key: wp.froala_api,
      toolbarButtons: [
        'bold',
        'italic',
        'underline',
        'insertLink',
        'clearFormatting',
        'html'
      ],
      toolbarBottom: true,
      spellcheck: false
    });


    $newOptin.find('.opt-in-show-unchk-label').attr('for', `opt_in_${newOptinfId}_show_unchk`);
    $newOptin.find('.opt-in-show-unchk').attr('id', `opt_in_${newOptinfId}_show_unchk`)
      .attr('name', `opt_in_${newOptinfId}`);
    if ( is_brazil ) {
      $newOptin.find('.opt-in-show-unchk').attr("checked", true);
    }

    $newOptin.find('.opt-in-show-chk-label').attr('for', `opt_in_${newOptinfId}_show_chk`);
    $newOptin.find('.opt-in-show-chk').attr('id', `opt_in_${newOptinfId}_show_chk`)
      .attr('name', `opt_in_${newOptinfId}`);
    if ( ! is_brazil ) {
      $newOptin.find('.opt-in-show-chk').attr("checked", true);
    } else {
      $newOptin.find('.opt-in-show-chk').attr("checked", false);
    }

    $newOptin.find('.opt-in-hide-opt-label').attr('for', `opt_in_${newOptinfId}_hide_opt`);
    $newOptin.find('.opt-in-hide-opt').attr('id', `opt_in_${newOptinfId}_hide_opt`)
      .attr('name', `opt_in_${newOptinfId}`);

    $newOptin.find('input[id$="_required"]').attr('id', `${newOptinfId}_required`)
      .attr('name', `${newOptinfId}_required`);

    $newOptin.find('input[id$="_required_selector"]').attr('id', `${newOptinfId}_required_selector`)
      .attr('name', `${newOptinfId}_required`).on('click', function () {
      const $el = $(`#${newOptinfId}_required`);
      const value = $el.val() === 'true' ? 'false' : 'true';
      $el.val(value);
    });

    $newOptin.find('.mailing-list-conf-selector').attr('id', `mailing-list-conf-${newOptinfId}`);

    $newOptin.attr('data-sfid', sfId);
    $newOptin.attr('data-req', 'true');
    $newOptin.attr('data-optin', 'show_chk');
    $newOptin.attr('data-name', name);
    $newOptin.attr('data-text', `Subscribe to the ${name} mailing list`);

    const $lastOptin = $(`#${$elem.data("tableid")} tbody tr:last`);
    if($lastOptin.length) {
      $lastOptin.after($newOptin);
    } else {
      $(`#${$elem.data("tableid")} tbody`).append($newOptin);
    }

    M.toast({
      html: 'Mailing List Added',
      classes: 'success-toast'
    });
    removeFieldInit();

  });

  $("textarea.froala-editor").each(function() {
    $( this ).froalaEditor({
      key: wp.froala_api,
      toolbarButtons: [
        'bold',
        'italic',
        'underline',
        'insertLink',
        'clearFormatting',
        'html'
      ],
      toolbarBottom: true,
      spellcheck: false
    });
  });

  $(".mailing-list-cards").on("change", ".opt-in-settings", function () {

    const $elem = $( this ).closest('td').find(".depend-on-hide-opt");

    if ( "hide_opt" === $( this ).val() ) {
      $elem.hide();
    } else {
      $elem.removeClass("hide").show();
    }
  })
}

function getMlCollection(mlType) {
  const mlCollection = {};
  const $table = $(`#ml-${mlType}-page-table`);
  const $mlElems = $table.find('tbody tr');

  if ($mlElems.length) {
    $mlElems.each(function() {
      const elId = `${$(this).data('ml-type')}_${$(this).data('sfid')}`;
      mlCollection[$(this).data('sfid')] = {
        name: $(this).data('name'),
        req: $(this).find(`input[id="${elId}_required"]`).val(),
        optin: $(this).find(`input[name="opt_in_${elId}"]:checked`).val(),
        text: $(this).find(`textarea#mailing-list-name-${elId}`).val(),
        conf: $(this).find(`select[id="mailing-list-conf-${elId}"]`).val(),
      }
    });
  }
  return mlCollection;
}

export {initOptinsList, getMlCollection};
