/**
 * Foreach Loop
 * Will run a foreach loop on elements from the DOM
 * Ex. grabAll = document.querySelectorAll('.some-element-selector');
 * @param  {obj}      nodeList NodeList of elements
 * @param  {Function} callback Function to call on each loop
 * @param  {[type]}   scope    [description]
 * @module utils/forEach
 */
export default function(nodeList, callback, scope) {
  for (let i = 0; i < nodeList.length; i++) {
    callback.call(scope, i, nodeList[i]);
  }
}
