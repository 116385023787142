import uuidv4 from 'uuid/v4';
import { split } from 'lodash-es';
import { errorToast } from '../../utils/toasts';

function eventAddExistingPl( tabID = false ) {
  let provider;
  let type;

  if ($('#spotify-tab').hasClass('active')) {
    provider = 'spotify';
    if (tabID === 'follow' || $('#spotify-container #tabs-sticky a[data-tablink="follow"]').hasClass('active')) {
      type = 'existing';
    } else if (tabID === 'custom' || $('#spotify-container #tabs-sticky a[data-tablink="custom"]').hasClass('active')) {
      type = 'existing-other';
    }
  }

  if ($('#amazon-tab').hasClass('active')) {
    provider = 'amazon';
    type = 'existing';
  }

  if ($('#deezer-tab').hasClass('active')) {
    provider = 'deezer';
    if ($('#campaign_deezer_playlist_mode').val() === 'existing') {
      type = 'existing';
    } else {
      type = 'existing-other';
    }
  }

  const $plAdd = $(
    `#integrations-tabs-container .${provider}-add-playlist-${type}`
  );
  if ($plAdd.length) {
    $plAdd.off().on('click', addExistingPl);
    eventExistingPlRemove(provider);
  }
}

function addExistingPl() {
  let provider;
  let type;
  let collection;

  const $container = $(this).closest(".playlist-follow-container");
  console.log($container);

  if ($('#spotify-tab').hasClass('active')) {
    provider = 'spotify';
    if ($('#tabs-sticky a[data-tablink="follow"]').hasClass('active')) {
      type = 'existing';
      collection = 'collection';
    } else {
      type = 'existing-other';
      collection = 'collection-other';
    }
  }

  if ($('#amazon-tab').hasClass('active')) {
    provider = 'amazon';
    if ($('#tabs-sticky a[data-tablink="follow"]').hasClass('active')) {
      type = 'existing';
      collection = 'collection';
    } else {
      type = 'existing-other';
      collection = 'collection-other';
    }
  }

  if ($('#deezer-tab').hasClass('active')) {
    provider = 'deezer';
    if ($('#campaign_deezer_playlist_mode').val() === 'existing') {
      type = 'existing';
      collection = 'collection';
    } else {
      type = 'existing-other';
      collection = 'collection-other';
    }
  }

  const totalPl = $container.find(`.${provider}-${collection} tbody tr`).length;
  const $nameInput = $container.find(`.${provider}-add-playlist-name-${type}`);
  const $uriInput = $container.find(`.${provider}-add-playlist-uri-${type}`);
  const name = $nameInput.val();
  const uri = $uriInput.val();

  if (!validatePlaylistUri(uri, provider)) {
    errorToast(`Invalid Playlist ` + ( provider == 'amazon' ? 'URL' : 'URI') );
    $uriInput.addClass('invalid');
    return;
  }

  if (name === '') {
    errorToast('Add a name for this playlist');
    $nameInput.addClass('invalid');
    return;
  }

  if (totalPl >= 5) {
    errorToast('5 Playlists Max');
    return;
  }

  if (type === 'existing-other') {
    type = 'existing_other';
  } else {
    type = 'existing';
  }

  const elemId = uuidv4();
  const element = `
  <tr id="${elemId}">
    <td class="edit-entry playlist-name">
      <input type="text" class="browser-default" name="${provider}_${type}_playlist[][name]" value="${name}">
    </td>
    <td class="edit-entry playlist-uri">
      <input type="text" class="browser-default" name="${provider}_${type}_playlist[][uri]" value="${uri}">
    </td>
    <td class="remove-field-${provider}" data-removeid="${elemId}"><a><i class="material-icons">delete_forever</i></a></td>
    </tr>
  `;

  $(`.${provider}-${collection} tbody`).append(element);

  $nameInput.val('');
  $uriInput.val('');
  $uriInput.removeClass('invalid');
  $nameInput.removeClass('invalid');

  reIndexFields();
}

function eventExistingPlRemove(provider) {
  $(`.${provider}-collection`).on(
    'click',
    `.remove-field-${provider}`,
    removeExistingPlElem
  );
  $(`.${provider}-collection-other`).on(
    'click',
    `.remove-field-${provider}`,
    removeExistingPlElem
  );
}

function reIndexFields() {
  let provider;
  if ($('#spotify-tab').hasClass('active')) {
    provider = 'spotify';
  } else {
    provider = 'deezer';
  }

  $(`.${provider}-collection-other tbody tr`).each(function(index) {
    $($(this)[0])
      .find('.playlist-name input')
      .attr('name', `${provider}_existing_other_playlist[${index}][name]`);
    $($(this)[0])
      .find('.playlist-uri input')
      .attr('name', `${provider}_existing_other_playlist[${index}][uri]`);
  });

  $(`.${provider}-collection tbody tr`).each(function(index) {
    $($(this)[0])
      .find('.playlist-name input')
      .attr('name', `${provider}_existing_playlist[${index}][name]`);
    $($(this)[0])
      .find('.playlist-uri input')
      .attr('name', `${provider}_existing_playlist[${index}][uri]`);
  });
}

function removeExistingPlElem() {
  const removeId = $(this).data('removeid');
  $(`#${removeId}`).remove();
  reIndexFields();
}

function validatePlaylistUri(value, provider) {
  if (provider === 'spotify') {
    return /^spotify:(?:user:[\w.]*:)*playlist:\b\w*$/i.test(value);
  }
  if (provider === 'amazon') {
    return /^https:\/\/music.amazon.com\/playlists\/[a-zA-Z0-9]{10}$/i.test(value) || /^https:\/\/music.amazon.com\/user-playlists\/[a-zA-Z0-9]{36}$/i.test(value);
  }

  return /^(http|https):\/\/www.deezer.com.*\/playlist\/\d+\/?$/i.test(value);
}

export { eventAddExistingPl };
