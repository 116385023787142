import empty from "./empty";
import $ from "jquery";
import forEach from "./forEach";
import fixedEncodeURIComponent from "./fixedEncodeURIComponent";

export default function initGenderOptionsModal(e) {
  const $genderOptionsButton = $("#gender-options button");
  if ($genderOptionsButton.length) {
    $genderOptionsButton.off("click").on('click', genderOptionsModal);
  }
}

function genderOptionsModal(e) {
  e.preventDefault();
  const target = e.target.nextElementSibling;
  const genderModalContainer = document.querySelector(
    '#modal-bu-gender'
  );
  M.Modal.init(genderModalContainer, {
    opacity: 0.8,
    dismissible: false,
    onOpenStart() {
      let val = target.value;
      if (empty(val)) {
        val = $("#default-gender-options").val();
      }

      let obj = decodeURIComponent(val);
      obj = JSON.parse(obj);
      Object.keys(obj).forEach(key => {
        const {label, value} = obj[key];
        const element = `
            <td class="edit-entry option-label"><input type="text" class="browser-default" value="${label}"></td>
            <td class="edit-entry option-value"><input type="text" class="browser-default" value="${value}" disabled="disabled"></td>
            `;
        const container = document.querySelector(
          '#modal-bu-gender tbody'
        );
        const d = document.createElement('tr');
        d.innerHTML = element;
        container.appendChild(d);
      });
    },
    onCloseEnd() {
      const ffOptContainer = {};
      const ffOptions = document.querySelectorAll(
        '#modal-bu-gender tbody tr'
      );
      forEach(ffOptions, (index, value) => {
        const ffOpt = {};
        const labelItem = value.querySelector('.option-label input').value;
        const valueItem = value.querySelector('.option-value input').value;
        ffOpt.label = labelItem;
        ffOpt.value = valueItem;
        ffOptContainer[index] = ffOpt;
      });
      forEach(ffOptions, (index, value) => {
        value.remove();
      });
      let string = JSON.stringify(ffOptContainer);
      string = fixedEncodeURIComponent(string);
      target.value = string;
      const instance = M.Modal.getInstance(genderModalContainer);
      instance.destroy();
    }
  });
  const instance = M.Modal.getInstance(genderModalContainer);
  instance.open();

}
