import uuidv4 from 'uuid/v4';
import { errorToast } from '../../../utils/toasts';

function eventAddAmazonArtist() {
  const $amazonAdd = $('#amazon-add-artist');
  if ($amazonAdd.length) {
    $amazonAdd.off().on('click', addArtistAmazon);
    eventAmazonArtistRemove();
  }
}

function addArtistAmazon() {
  const totalArtists = $('.amazon-artist-collection tbody tr').length;

  const $nameInput = $('#amazon-add-artist-name');
  const $uriInput = $('#amazon-add-artist-uri');
  const name = $nameInput.val();
  const uri = $uriInput.val();

  if (!validateArtistURL(uri)) {
    errorToast('Invalid Amazon artist URL');
    $uriInput.addClass('invalid');
    return;
  }

  if (name === '') {
    errorToast('Add a name for this Amazon artist');
    $nameInput.addClass('invalid');
    return;
  }

  if (totalArtists >= 20) {
    errorToast('20 Amazon Artists Max');
    return;
  }

  const elemId = uuidv4();
  const element = `
  <tr id="${elemId}">
  <td class="edit-entry artist-name">
    <input type="text" class="browser-default" name="amazon_artist_follow[][name]" value="${name}">
  </td>
  <td class="edit-entry artist-uri">
    <input type="text" class="browser-default" name="amazon_artist_follow[][uri]" value="${uri}">
  </td>
  <td class="remove-field-amazon" data-removeid="${elemId}"><a><i class="material-icons">delete_forever</i></a></td>
  </tr>
  `;

  $('.amazon-artist-collection tbody').append(element);
  $nameInput.val('');
  $uriInput.val('');
  $uriInput.removeClass('invalid');
  $nameInput.removeClass('invalid');

  reIndexFields();
}

function eventAmazonArtistRemove() {
  $('.amazon-artist-collection').on(
    'click',
    '.remove-field-amazon',
    removeAmazonArtistElem
  );
}

function reIndexFields() {
  $(`.amazon-artist-collection tbody tr`).each(function(index) {
    $(this)
      .find('.artist-name input')
      .attr('name', `amazon_artist_follow[${index}][name]`);
    $(this)
      .find('.artist-uri input')
      .attr('name', `amazon_artist_follow[${index}][uri]`);
  });
}

function removeAmazonArtistElem() {
  const removeId = $(this).data('removeid');
  $(`#${removeId}`).remove();
  reIndexFields();
}

function validateArtistURL(value) {
  return /^https:\/\/music.amazon.com\/artists\/B0.*$/i.test(value) ||
    /^https:\/\/music.amazon.com\/profiles\/.*$/i.test(value);
}

export { eventAddAmazonArtist };
