import $ from "jquery";
import {errorToast, showToast} from "../../utils/toasts";
import timeZoneFix from "../../utils/timeZoneFix";

function reindexQuestions() {
  // replace array indexes
  $('#playlist-generator-questions-collection > li').each(function (index, elem) {
    replaceIndexes(index, elem);
  });
}

function reindexLibProfiles() {
  const playlistsIndexes = [];
  $('#playlist-generator-profiles-collection > li').each(function (index, element) {
    replaceIndexes(index, element);
    const oldIndex = Number.parseInt($(element).attr('id').split('_')[1]);
    playlistsIndexes.push(oldIndex);
  });
  reindexLibProfilePlaylists(playlistsIndexes)
}

function reindexLibProfilePlaylists(newOrder) {

}

function replaceIndexes(index, elem) {
  $(elem)
    .find('input, select')
    .each(function() {
      const placeholder = $(this).prop('placeholder');
      if (placeholder) {
        $(this).prop(
          'placeholder',
          $(this)
            .prop('placeholder')
            .replace(/[0-9]/g, index + 1)
        );
      }
      $(this).attr(
        'name',
        $(this)
          .attr('name')
          .replace(/[0-9]/g, index)
      );
    });
}

function initQuiz() {
  const $questionsCollection = $('#playlist-generator-questions-collection');
  $('.add-playlist-generator-question')
    .off()
    .on('click', function(e) {
      e.preventDefault();
      const numQuestions = $('#playlist-generator-questions-collection > li')
        .length;

      if (numQuestions < 5) {
        // clone
        const questionClone = $(
          '#playlist-generator-questions-collection > li:last-child'
        )
          .clone()
          .find('input, select')
          .val('')
          .end();

        if (questionClone.find('.answer-row').length > 2) {
          questionClone.find('.answer-row-c').remove();
          questionClone.find('.answer-row-d').remove();
        }

        $questionsCollection.append(questionClone);
        reindexQuestions();
      } else {
        M.toast({
          html: '5 questions is the maximum.',
          classes: 'error-toast'
        });
      }
    });

  $questionsCollection
    // .off()
    .on('click', '.delete-question', function(e) {
      e.preventDefault();
      $(this)
        .parent()
        .parent()
        .remove();
      reindexQuestions();
    });

  $questionsCollection
    // .off()
    .on('click', '.add-answer', function(e) {
      e.preventDefault();
      const answers = $(this)
        .parent()
        .parent()
        .find('.answer-row');

      if (answers.length < 6) {
        const currentIndex = answers.length - 1;
        const answerClone = $(answers[currentIndex]).clone();

        let replaceLetter = '';

        switch ( currentIndex) {
          case 1:
            replaceLetter = 'c';
            break;
          case 2:
            replaceLetter = 'd';
            break;
          case 3:
            replaceLetter = 'e';
            break;
          case 4:
            replaceLetter = 'f';
            break;
        }

        answerClone.find('.delete-answer').remove();

        answerClone.find('.col:first-child label').text(`Answer ${replaceLetter}`);
        answerClone.find('.col:last-child label').text(`Map to playlist`);
        answerClone
          .find('input')
          .attr(
            'name',
            `playlist_generator_questions[0][answer_${replaceLetter}]`
          )
          .val('');

        answerClone
          .find('select')
          .attr(
            'name',
            `playlist_generator_questions[0][answer_${replaceLetter}_mapping]`
          )
          .val('');

        answerClone
          .find('select')
          .after(
            '<a href="#" class="delete-answer"><i class="fal fa-trash-alt" /></a>'
          );

        $(this)
          .parent()
          .before(answerClone);
      }
    });

  $questionsCollection
    // .off()
    .on('click', '.delete-answer', function(e) {
      e.preventDefault();
      $(this)
        .closest('.answer-row')
        .remove();
    });
}

function initSls() {
  const $profilesCollection = $('#playlist-generator-profiles-collection');
  $('.add-sls-profile')
    .off()
    .on('click', function (e) {
      e.preventDefault();
      const numProfiles = $('#playlist-generator-profiles-collection > li').length;

      if (numProfiles < 10) {
        // clone
        const profileClone = $(
          '#playlist-generator-profiles-collection > li:last-child'
        )
          .clone()
          .attr('id', `${$('input[name=campaign_id]').val()}_${numProfiles}`)
          .find('input, select')
          .val('')
          .end();

        const $spotifyContent = profileClone.find(".add-edit-spotify-content");
        if($spotifyContent.length !== 0) {
          $spotifyContent.find(".edit-sls-profile-tracks").attr("data-profile-num", numProfiles);
          $spotifyContent.find(".tracks-added").html("0 Item Added");
          $spotifyContent.find("input[name='sls_profile_playlist_number[]']").val(numProfiles);
        }

        $profilesCollection.append(profileClone);
        reindexLibProfiles();
      } else {
        M.toast({
          html: '10 library profiles is the maximum.',
          classes: 'error-toast'
        });
      }
    });

  $profilesCollection.on('click', '.delete-profile', function (e) {
    e.preventDefault();
    $(this)
      .parent()
      .parent()
      .remove();
    reindexLibProfiles();
  });
}

function checkEmptyPlaylists(campaignId) {
  $.ajax({
    url: wp.ajaxurl,
    method: 'POST',
    data: {
      action: 'check_empty_playlists',
      campaign_id: campaignId
    },
    error() {
      errorToast('Error while getting responce from server');
    },
    success(response) {
      if (response.data.length > 0) {
        showToast(response.data[0], 'warning-toast');
      }
    }
  });
}

export { initQuiz, reindexQuestions, initSls, reindexLibProfiles, checkEmptyPlaylists };
