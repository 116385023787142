export function startLoading() {
  $('.smf-loading-animation').removeClass('hide');
  $('.preloader-background').removeClass('hide');
  $('html').on('scroll.scrollTopLinkAnimation', function() {
    $(this).stop();
    $(this).off('.scrollTopLinkAnimation');
  });
  $('html').animate(
    {
      scrollTop: 0
    },
    700,
    function() {
      $(this).off('.scrollTopLinkAnimation');
    }
  );
}

export function stopLoading() {
  $('.smf-loading-animation').addClass('hide');
  $('.preloader-background').addClass('hide');
}

export function startContentLoading() {
  $('.content-loading').removeClass('hide');
}

export function stopContentLoading() {
  $('.content-loading').addClass('hide');
}
