/**
 * Redirect To Login
 *
 * @module utils/loginRedirect
 */

export default function() {
  if ($('#dashboard-reload #login').length) {
    window.location.href =
      window.location.origin +
      '/wp-login.php?loggedout=true&user_disabled=true';
  }
}
