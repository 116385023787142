/**
 * Encodes a text string as a valid component of a URI with same changes.
 *
 * @param str A value representing an encoded URI component.
 * @returns {string}
 * @module utils/fixedEncodeURIComponent
 */
export default function fixedEncodeURIComponent(str) {
  return encodeURIComponent(str)
    .replace(/[!'()]/g, escape)
    .replace(/\*/g, '%2A');
}
