import uuidv4 from 'uuid/v4';
import { errorToast } from '../../../utils/toasts';

function eventAddSpotifyPodcast() {
  const $spotifyAdd = $('#spotify-add-podcast');
  if ($spotifyAdd.length) {
    $spotifyAdd.off().on('click', addPodcastSpotify);
    eventSpotifyPodcastRemove();
  }
}

function addPodcastSpotify() {
  const totalPodcasts = $('.spotify-podcast-collection tbody tr').length;

  const $nameInput = $('#spotify-add-podcast-name');
  const $uriInput = $('#spotify-add-podcast-uri');
  const name = $nameInput.val();
  const uri = $uriInput.val();

  if (!validatePodcastUri(uri)) {
    errorToast('Invalid Spotify podcast URI');
    $uriInput.addClass('invalid');
    return;
  }

  if (name === '') {
    errorToast('Add a name for this Spotify podcast');
    $nameInput.addClass('invalid');
    return;
  }

  if (totalPodcasts >= 20) {
    errorToast('20 Spotify Podcasts Max');
    return;
  }

  const elemId = uuidv4();
  const element = `
  <tr id="${elemId}">
  <td class="edit-entry podcast-name">
    <input type="text" class="browser-default" name="spotify_podcast_follow[][name]" value="${name}">
  </td>
  <td class="edit-entry podcast-uri">
    <input type="text" class="browser-default" name="spotify_podcast_follow[][uri]" value="${uri}">
  </td>
  <td class="remove-field-spotify" data-removeid="${elemId}"><a><i class="material-icons">delete_forever</i></a></td>
  </tr>
  `;

  $('.spotify-podcast-collection tbody').append(element);
  $nameInput.val('');
  $uriInput.val('');
  $uriInput.removeClass('invalid');
  $nameInput.removeClass('invalid');

  reIndexFields();
}

function eventSpotifyPodcastRemove() {
  $('.spotify-podcast-collection').on(
    'click',
    '.remove-field-spotify',
    removeSpotifyPodcastElem
  );
}

function reIndexFields() {
  $(`.spotify-podcast-collection tbody tr`).each(function(index) {
    $(this)
      .find('.podcast-name input')
      .attr('name', `spotify_podcast_follow[${index}][name]`);
    $(this)
      .find('.podcast-uri input')
      .attr('name', `spotify_podcast_follow[${index}][uri]`);
  });
}

function removeSpotifyPodcastElem() {
  const removeId = $(this).data('removeid');
  $(`#${removeId}`).remove();
  reIndexFields();
}

function validatePodcastUri(value) {
  return /^spotify:(?:user:[\w.]*:)*show:\b[\w.-]+$/i.test(value);
}

export { eventAddSpotifyPodcast };
