/*!
 * froala_editor v2.9.3 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2019 Froala Labs
 */
import $ from "jquery";

!(function (n) {
  "function" == typeof define && define.amd
    ? define(["jquery"], n)
    : "object" == typeof module && module.exports
      ? (module.exports = function (e, t) {
        return t === undefined && (t = "undefined" != typeof window ? require("jquery") : require("jquery")(e)), n(t);
      })
      : n(window.jQuery);
})(function (c) {
  c.extend(c.FE.POPUP_TEMPLATES, { "embedon.insert": "[_BUTTONS_][_URL_LAYER_]", "embedon.edit": "[_BUTTONS_]" }),
    c.extend(c.FE.DEFAULTS, { embedonInsertButtons: ["embedonBack", "|"], embedonEditButtons: ["embedonRemove"] }),
    (c.FE.PLUGINS.embedon = function (r) {
      function t() {
        r.events.on("html.processGet", i),
          r.events.$on(r.$el, "click touchend", "div.fr-embedly", d),
          r.events.on("mousedown window.mousedown", m),
          r.events.on("window.touchmove", b),
          r.events.on("mouseup window.mouseup", u),
          r.events.on("commands.mousedown", function (e) {
            0 < e.parents(".fr-toolbar").length && u();
          }),
          r.events.on("blur video.hideResizer commands.undo commands.redo element.dropped", function () {
            u(!0);
          }),
          r.events.on("element.beforeDrop", function (e) {
            if (e.hasClass("fr-embedly")) return e.html(e.attr("data-original-embed")), e;
          }),
          r.events.on(
            "keydown",
            function (e) {
              var t = e.which;
              return !s || (t != c.FE.KEYCODE.BACKSPACE && t != c.FE.KEYCODE.DELETE)
                ? s && t == c.FE.KEYCODE.ESC
                  ? (u(!0), e.preventDefault(), !1)
                  : s && t != c.FE.KEYCODE.F10 && !r.keys.isBrowserAction(e)
                    ? (e.preventDefault(), !1)
                    : void 0
                : (e.preventDefault(), f(), !1);
            },
            !0
          ),
          r.events.on(
            "toolbar.esc",
            function () {
              if (s) return r.events.disableBlur(), r.events.focus(), !1;
            },
            !0
          ),
          r.events.on(
            "toolbar.focusEditor",
            function () {
              if (s) return !1;
            },
            !0
          ),
          r.events.on("snapshot.after", function (e) {
            var t = r.doc.createElement("div");
            (t.innerHTML = e.html), i(t), (e.html = t.innerHTML);
          }),
          r.events.on("html.set", function() {
            n()
          }),
          r.events.on("initialized", function () {
            n();
          } ),
          l(!0);
      }
      function n() {
        r.$el.find('.fr-embedly').each( function(e) {
          $( this ).attr("contenteditable", !1).attr("draggable", !0).addClass("fr-draggable").addClass("fr-draggable");
          $( this ).html( getHtml( $( this ).data('original-embed')) );
        })
      }
      var s, o;
      function d(e) {
        (s = c(this)),
          (function () {
            o ||
            (function () {
              r.shared.$embedon_resizer
                ? ((o = r.shared.$embedon_resizer),
                  r.shared.$embedon_overlay,
                  r.events.on(
                    "destroy",
                    function () {
                      o.appendTo(c("body:first"));
                    },
                    !0
                  ))
                : ((r.shared.$embedon_resizer = c('<div class="fr-embedly-resizer"></div>')),
                  (o = r.shared.$embedon_resizer),
                  r.events.$on(
                    o,
                    "mousedown",
                    function (e) {
                      e.stopPropagation();
                    },
                    !0
                  ));
              r.events.on(
                "shared.destroy",
                function () {
                  o.html("").removeData().remove(), (o = null);
                },
                !0
              );
            })();
            (r.$wp || r.$sc).append(o),
              o.data("instance", r),
              o
                .css("top", (r.opts.iframe ? s.offset().top - 1 + r.$iframe.position().top : s.offset().top - r.$wp.offset().top - 1) + r.$wp.scrollTop())
                .css("left", (r.opts.iframe ? s.offset().left - 1 : s.offset().left - r.$wp.offset().left - 1) + r.$wp.scrollLeft())
                .css("width", s.outerWidth())
                .css("height", s.height())
                .addClass("fr-active");
          })(),
          (function () {
            var e = r.popups.get("embedon.edit");
            e ||
            (e = (function () {
              var e = "";
              if (0 < r.opts.embedonEditButtons.length) {
                (e += '<div class="fr-buttons">'), (e += r.button.buildList(r.opts.embedonEditButtons));
                var t = { buttons: (e += "</div>") },
                  n = r.popups.create("embedon.edit", t);
                return (
                  r.events.$on(r.$wp, "scroll.emebdon-edit", function () {
                    s && r.popups.isVisible("embedon.edit") && (r.events.disableBlur(), d.call(s.get(0)));
                  }),
                    n
                );
              }
              return !1;
            })());
            if (e) {
              r.popups.setContainer("embedon.edit", r.$sc), r.popups.refresh("embedon.edit");
              var t = s.offset().left + s.outerWidth() / 2,
                n = s.offset().top + s.outerHeight();
              r.popups.show("embedon.edit", t, n, s.outerHeight());
            }
          })();
      }
      function i(e) {
        if (e && r.node.hasClass(e, "fr-embedly"))
          (e.innerHTML = e.getAttribute("data-original-embed")),
            e.removeAttribute("draggable"),
            e.removeAttribute("contenteditable"),
            e.setAttribute("class",
              (e.getAttribute("class") || "").replace("fr-draggable", "")
            );
        else if (e && e.nodeType == Node.ELEMENT_NODE) for (var t = e.querySelectorAll(".fr-embedly"), n = 0; n < t.length; n++) i(t[n]);
      }
      function l(e) {
        if (e) return r.popups.onRefresh("embedon.insert", a), !0;
        var t = "";
        0 < r.opts.embedonInsertButtons.length && ((t += '<div class="fr-buttons">'), (t += r.button.buildList(r.opts.embedonInsertButtons)), (t += "</div>"));
        var n = {
          buttons: t,
          url_layer:
            '<div class="fr-embedly-layer fr-active fr-layer" id="fr-embedly-layer-' +
            r.id +
            '"><div class="fr-input-line"><input id="fr-embedly-layer-text-' +
            r.id +
            '" type="text" placeholder="' +
            r.language.translate("Paste in a URL to embed") +
            '" tabIndex="1" aria-required="true"></div><div class="fr-action-buttons"><button type="button" class="fr-command fr-submit" data-cmd="embedonInsert" tabIndex="2" role="button">' +
            r.language.translate("Insert") +
            "</button></div></div>",
        };
        return r.popups.create("embedon.insert", n);
      }
      function a() {
        r.popups.get("embedon.insert").find(".fr-embedly-layer input").val("").trigger("change");
      }
      function e(e) {
        if (e.length) {
          var t = "<a href='" + e + "' data-card-branding='0' class='embedly-card'></a>";
          r.html.insert('<div class="fr-embedly fr-draggable" draggable="true" contenteditable="false" data-original-embed="' + t + '" >' + t + "</div>"), r.popups.hideAll();
        }
      }
      function f() {
        if (s && !1 !== r.events.trigger("embedon.beforeRemove", [s])) {
          var e = s;
          r.popups.hideAll(), u(!0), r.selection.setBefore(e.get(0)) || r.selection.setAfter(e.get(0)), e.remove(), r.selection.restore(), r.html.fillEmptyBlocks(), r.undo.saveStep(), r.events.trigger("video.removed", [e]);
        }
      }
      function u(e) {
        s && (r.shared.embedon_exit_flag || !0 === e) && (o.removeClass("fr-active"), r.toolbar.enable(), s.removeClass("fr-active"), (s = null), b());
      }
      function m() {
        r.shared.embedon_exit_flag = !0;
      }
      function b() {
        r.shared.embedon_exit_flag = !1;
      }
      function g() {
        r.shared.embedon_exit_flag = !1;
      }

      function getHtml(e) {
        let value = $.trim($(e).attr('href'));
        let href,id,iheight;
        switch (true)
        {
          // youtube
          case /youtube\.com\/playlist\?list=/.test(value):
            id = value.match(/(?<=list=)(.*)(?=&)|(?<=list=)(.*)/)[0];
            href = 'https://www.youtube.com/embed/?list=' + id +'&listType=playlist&showinfo=0&wmode=opaque&enablejsapi=1&widgetid=1';
            break;
          case /youtube\.com\/embed\//.test(value):
            href = value;
            break;
          case /youtube\.com\/watch\?/.test(value):
            id = value.match(/(?<=v=)(.*)(?=&)|(?<=v=)(.*)/)[0];
            href = 'https://www.youtube.com/embed/' + id +'?showinfo=0&wmode=opaque&enablejsapi=1&widgetid=1';
            break;
          case /youtube\.com\/shorts\//.test(value):
            id = value.match(/shorts\/(.*)/)[1];
            href = 'https://www.youtube.com/embed/' + id +'?showinfo=0&wmode=opaque&enablejsapi=1&widgetid=1';
            break;
          case /youtu\.be\//.test(value):
            id = value.match(/(?<=youtu\.be\/)(.*)(?=&)|(?<=youtu\.be\/)(.*)/)[0];
            href = 'https://www.youtube.com/embed/' + id +'?showinfo=0&wmode=opaque&enablejsapi=1&widgetid=1';
            break;

          // Apple
          case /music\.apple\.com\//.test(value) || /itunes\.apple\.com\//.test(value):
            if ( ! /\/artist\//.test(value) ) {
              let region;
              if (/(?<=apple\.com\/..\/)/.test(value)) {
                region = value.match(/(?<=apple\.com\/)(..)/)[0];
                id = value.match(/(?<=apple\.com\/..\/)([^/]*\/[^/]*\/[^/]*\/)|(?<=apple\.com\/..\/)(.*)(?=\?)|(?<=apple\.com\/..\/)(.*)/)[0]
              } else {
                id = value.match(/(?<=apple\.com\/)([^/]*\/[^/]*\/[^/]*\/)|(?<=apple\.com\/)(.*)(?=\?)|(?<=apple\.com\/)(.*)/)[0]
              }
              href = 'https://embed.music.apple.com/' + (region ? region + '/' : '') + id;
            }
            break;
          // spotify
          case /spotify:/.test(value):
            let spotifyDir = value.match(/(?<=spotify:)(.*)(?=:)/ )[0];
            id = value.match(/(?<=spotify:.*:)(.*)/ )[0]
            iheight = 352;
            href = 'https://open.spotify.com/embed/' + spotifyDir + '/' + id;
            break;
          case /open\.spotify\.com\//.test(value):
            if ( /(?<=open\.spotify\.com\/embed\/)/.test(value)) {
              id = value.match(/(?<=open\.spotify\.com\/embed\/)(.*)(?=\?)|(?<=open\.spotify\.com\/embed\/)(.*)/ )[0]
            } else {
              id = value.match(/(?<=open\.spotify\.com\/)(.*)(?=\?)|(?<=open\.spotify\.com\/)(.*)/ )[0]
            }
            iheight = 352;
            href = 'https://open.spotify.com/embed/' + id;
            break;

          // deezer
          case /deezer\.com\//.test(value):
            if ( !/\/artist\//.test(value) ) {
              if (/(?<=deezer\.com\/..\/)/.test(value)) {
                id = value.match(/(?<=deezer\.com\/..\/)([^/]*\/[^/]*\/[^/]*\/)|(?<=deezer\.com\/..\/)(.*)(?=\?)|(?<=deezer\.com\/..\/)(.*)/)[0]
              } else {
                id = value.match(/(?<=deezer\.com\/)([^/]*\/[^/]*\/[^/]*\/)|(?<=deezer\.com\/)(.*)(?=\?)|(?<=deezer\.com\/)(.*)/)[0]
              }
              href = 'https://widget.deezer.com/widget/dark/' + id;
            }
            break;

          // Amazon
          case /music\.amazon\..*\//.test(value):
            id = value.match(/music\.amazon\..*\/playlists\/(.*)$|music\.amazon\..*\/user-playlists\/(.*$)|music.amazon.com\/albums\/(.*$)|music.amazon.com\/tracks\/(.*$)/i ).filter(n => n)[1]
            href = 'https://music.amazon.com/embed/' + id;
            break;

          // Brightcove
          case /bcove\.video/.test(value):
            href = value;
            break;
          case /players\.brightcove\.net/.test(value):
            href = value;
            break;
        }

        if ( href ) {
          return '<iframe width="525" height="' + (iheight ? iheight : 315) + '" src="' + href + '" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style="max-width:100%"></iframe>';
        } else {
          if ( /twitter\.com\//.test(value) || /t\.co\//.test(value) || /x\.com\//.test(value) ) {
            let openGraph = "";
            $.ajax({
              url: wp.ajaxurl,
              method: 'POST',
              async: false,
              data: {
                action: 'froala_open_graph',
                url: value
              },
              error(error){
                console.log(error);
              },
              success(response) {
                openGraph =  JSON.parse(response);
              }
            });
            let html;
            if (openGraph.html.length) {
              html = '<div style="width:525px; height:315px; overflow-y:scroll; max-width:100%">' + openGraph.html + '</div>';
            } else {
              html = '<div class="embeded-card text-left">' +
                '<div class="hdr">' +
                '<span class="provider-favicon">' + (openGraph.favicon.length ? ('<img width=16px height=16px src="'+ openGraph.favicon + '" />' ) : '' ) + '</span>' +
                '<a class="provider-name" target="_blank" href="' + openGraph.providerUrl + '">' +openGraph.providerName + '</a>' +
                '</div>' +
                '<div class="bd">' +
                '<div class="art-bd">' +
                '<a target="_blank" href="'+ openGraph.url +'" >' +
                '<img class="art-bd-img" src="' + openGraph.image + '" >' +
                '</a>' +
                '</div>' +
                '<div class="txt-bd">' +
                '<h3 class="title">' +
                '<a target="_blank" href="'+ openGraph.url +'">' + openGraph.title + '</a>' +
                '</h3>' +
                '<p class="description">' + openGraph.description + '</p>' +
                '<a class="action" target="_blank" href="'+ openGraph.url +'">Read this on ' + openGraph.providerName + ' &gt;</a>' +
                '</div>' +
                '</div>' +
                '</div>';
            }
            return html;
          } else {
            return '';
          }
        }
      }
      return (
        (r.shared.embedon_exit_flag = !1),
          {
            _init: function () {
              if (!r.$wp) return !1;

              r.shared.embedonLoaded = !0, r.shared.embedonCallbacks = [], r.shared.embedonCallbacks.push(t);
              if (r.shared.embedonCallbacks)
                for (var e = 0; e < r.shared.embedonCallbacks.length; e++) r.shared.embedonCallbacks[e]()
            },
            showInsertPopup: function () {
              var e = r.$tb.find('.fr-command[data-cmd="embedon"]'),
                t = r.popups.get("embedon.insert");
              if ((t || (t = l()), !t.hasClass("fr-active")))
                if ((r.popups.refresh("embedon.insert"), r.popups.setContainer("embedon.insert", r.$tb), e.is(":visible"))) {
                  var n = e.offset().left + e.outerWidth() / 2,
                    s = e.offset().top + (r.opts.toolbarBottom ? 10 : e.outerHeight() - 10);
                  r.popups.show("embedon.insert", n, s, e.outerHeight());
                } else r.position.forSelection(t), r.popups.show("embedon.insert");
            },
            insert: function () {
              e(r.popups.get("embedon.insert").find(".fr-embedly-layer input").val());
              n();
            },
            remove: f,
            get: function () {
              return s;
            },
            add: e,
            back: function () {
              s ? (r.events.disableBlur(), s.trigger("click")) : (r.events.disableBlur(), r.selection.restore(), r.events.enableBlur(), r.popups.hide("embedon.insert"), r.toolbar.showInline());
            },
          }
      );
    }),
    c.FE.DefineIcon("embedon", { NAME: "thumbtack" }),
    c.FE.RegisterCommand("embedon", {
      undo: !0,
      focus: !0,
      title: "Embed URL",
      popup: !0,
      callback: function () {
        this.popups.isVisible("embedon.insert") ? (this.$el.find(".fr-marker").length && (this.events.disableBlur(), this.selection.restore()), this.popups.hide("embedon.insert")) : this.embedon.showInsertPopup();
      },
      plugin: "embedon",
    }),
    c.FE.RegisterCommand("embedonInsert", {
      undo: !0,
      focus: !0,
      callback: function () {
        this.embedon.insert();
      },
    }),
    c.FE.DefineIcon("embedonRemove", { NAME: "trash" }),
    c.FE.RegisterCommand("embedonRemove", {
      title: "Remove",
      undo: !1,
      callback: function () {
        this.embedon.remove();
      },
    }),
    c.FE.DefineIcon("embedonBack", { NAME: "arrow-left" }),
    c.FE.RegisterCommand("embedonBack", {
      title: "Back",
      undo: !1,
      focus: !1,
      back: !0,
      callback: function () {
        this.embedon.back();
      },
      refresh: function (e) {
        this.embedon.get() || this.opts.toolbarInline ? (e.removeClass("fr-hidden"), e.next(".fr-separator").removeClass("fr-hidden")) : (e.addClass("fr-hidden"), e.next(".fr-separator").addClass("fr-hidden"));
      },
    });
});
