import moment from 'moment';

/**
 * Timezone correction function
 * Convert php output dates to local timezone
 */
export default function() {
  $('.time-zone-value').each(function() {
    const date = $(this).text();
    const momentDate = moment(new Date(date));

    if (momentDate.isValid()) {
      const utcDate = moment.utc(momentDate);
      const localDate = moment.utc(utcDate).local();
      $(this).text(localDate.format('MM/DD/YY h:mma'));
    }
  });
}
