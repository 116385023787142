import $ from "jquery";

export default function initSocialStep() {
  console.log("initSocialStep");

  const $faslAddBtn = $(".fasl-add-btn");
  const $container = $("#fasl-block");

  let faslFieldsHidden = true;

  if($(".fasl-col.fasl-col-hide").length === 0) {
    $faslAddBtn.hide();
  }

  $faslAddBtn.on('click', function () {
    const $hiddenCols = $(".fasl-col.fasl-col-hide");
    $hiddenCols.first().removeClass('fasl-col-hide');
    if($hiddenCols.length === 1) {
      $faslAddBtn.hide();
    }
  })

  $(".fasl-remove-btn").on('click', function () {
    const $col = $(this).parent(".fasl-col");

    $faslAddBtn.show();
    $col.addClass("fasl-col-hide");

    $col.find(':input').each(function () {
      $(this).val('').change();
    });
  })

  $(document).mouseup(function(e)
  {


    if (!$container.is(e.target) && $container.has(e.target).length === 0)
    {
      if(!faslFieldsHidden) {
        hideEmptyFields();
      }
    } else {
      showEmptyFields();
    }
  });

  function showEmptyFields() {
    $("#fasl-block .input-field").removeClass('fasl-field-hide');
    faslFieldsHidden = false;
  }

  function hideEmptyFields() {
    $('#fasl-block .input-field input').each(function () {
      const $block = $(this).parent(".input-field ");
      if($(this).val() === '' && !$block.hasClass('act-text')) {
        $block.addClass('fasl-field-hide');
      }
    });
    faslFieldsHidden = true;
  }
}

