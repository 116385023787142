import empty from './empty';

/* global M */

/**
 * Show an error styled materialize toast
 * @param  {string} html    HTML code to display in toast
 * @param  {string} classes Classes to add to toast
 * @module utils/errorToast
 */
function errorToast(html, classes) {
  if (empty(html)) {
    html = 'Sorry, the system is experiencing difficulties';
  }
  if (empty(classes)) {
    classes = 'error-toast';
  }
  M.toast({
    html,
    classes
  });
}

/**
 * Show a materialize toast
 * @param  {string} html    HTML code to display in toast
 * @param  {string} classes Classes to add to toast
 * @module utils/showToast
 */
function showToast(html, classes) {
  if (empty(html)) {
    html = 'Success';
  }
  if (empty(classes)) {
    classes = 'success-toast';
  }
  M.toast({
    html,
    classes
  });
}

export { errorToast, showToast };
