import uuidv4 from 'uuid/v4';
import { errorToast } from '../../../utils/toasts';

function eventAddSpotifyArtist() {
  const $spotifyAdd = $('#spotify-add-artist');
  if ($spotifyAdd.length) {
    $spotifyAdd.off().on('click', addArtistSpotify);
    eventSpotifyArtistRemove();
  }
}

function addArtistSpotify() {
  const totalArtists = $('.spotify-artist-collection tbody tr').length;

  const $nameInput = $('#spotify-add-artist-name');
  const $uriInput = $('#spotify-add-artist-uri');
  const name = $nameInput.val();
  const uri = $uriInput.val();

  if (!validateArtistUri(uri)) {
    errorToast('Invalid Spotify artist URI');
    $uriInput.addClass('invalid');
    return;
  }

  if (name === '') {
    errorToast('Add a name for this Spotify artist');
    $nameInput.addClass('invalid');
    return;
  }

  if (totalArtists >= 20) {
    errorToast('20 Spotify Artists Max');
    return;
  }

  const elemId = uuidv4();
  const element = `
  <tr id="${elemId}">
  <td class="edit-entry artist-name">
    <input type="text" class="browser-default" name="spotify_artist_follow[][name]" value="${name}">
  </td>
  <td class="edit-entry artist-uri">
    <input type="text" class="browser-default" name="spotify_artist_follow[][uri]" value="${uri}">
  </td>
  <td class="remove-field-spotify" data-removeid="${elemId}"><a><i class="material-icons">delete_forever</i></a></td>
  </tr>
  `;

  $('.spotify-artist-collection tbody').append(element);
  $nameInput.val('');
  $uriInput.val('');
  $uriInput.removeClass('invalid');
  $nameInput.removeClass('invalid');

  reIndexFields();
}

function eventSpotifyArtistRemove() {
  $('.spotify-artist-collection').on(
    'click',
    '.remove-field-spotify',
    removeSpotifyArtistElem
  );
}

function reIndexFields() {
  $(`.spotify-artist-collection tbody tr`).each(function(index) {
    $(this)
      .find('.artist-name input')
      .attr('name', `spotify_artist_follow[${index}][name]`);
    $(this)
      .find('.artist-uri input')
      .attr('name', `spotify_artist_follow[${index}][uri]`);
  });
}

function removeSpotifyArtistElem() {
  const removeId = $(this).data('removeid');
  $(`#${removeId}`).remove();
  reIndexFields();
}

function validateArtistUri(value) {
  return /^spotify:(artist|user):\b[\w.-]+$/i.test(value);
}

export { eventAddSpotifyArtist };
