import uuidv4 from 'uuid/v4';
import { trimEnd, split, last } from 'lodash-es';
import { errorToast } from '../../utils/toasts';

function eventAddYtChannel() {
  const $ytAdd = $('#youtube-add-channel');
  if ($ytAdd.length) {
    $ytAdd.off().on('click', addCLYoutube);
    eventYtRemove();
  }
}

function addCLYoutube() {
  const totalChannels = $('.youtube-collection tbody tr').length;
  const $nameInput = $('#youtube-add-channel-name');
  const $urlInput = $('#youtube-add-channel-url');
  const name = $nameInput.val();
  let url = $urlInput.val();
  if (!validateChannelUrl(url)) {
    errorToast('Invalid YouTube Channel URL');
    $urlInput.addClass('invalid');
    return;
  }
  if (name === '') {
    errorToast('Add a name for this YouTube Channel');
    $nameInput.addClass('invalid');
    return;
  }
  if (totalChannels >= 20) {
    errorToast('20 Youtube Channels Max');
    return;
  }
  url = trimEnd(url, '/');
  url = split(url, '/');
  url = last(url);
  const elemId = uuidv4();
  const element = `
  <tr id="${elemId}">
  <td class="edit-entry channel-name">
    <input type="text" class="browser-default" name="channel_name[]" value="${name}">
  </td>
  <td class="edit-entry channel-url">
    <input type="text" disabled class="browser-default" name="channel_url[]" value="${url}">
  </td>
  <td class="remove-field-youtube" data-removeid="${elemId}"><a><i class="material-icons">delete_forever</i></a></td>
  </tr>
  `;
  $('.youtube-collection tbody').append(element);
  $nameInput.val('');
  $urlInput.val('');
  $urlInput.removeClass('invalid');
  $nameInput.removeClass('invalid');
}

function eventYtRemove() {
  $('.youtube-collection').on('click', '.remove-field-youtube', removeYtElem);
}

function removeYtElem() {
  const removeId = $(this).data('removeid');
  $(`#${removeId}`).remove();
}

function validateChannelUrl(value) {
  return /^(?:(?:http|https):\/\/|)(?:www\.|)youtube\.com\/(?:channel)\/(?:UC[a-zA-Z0-9\-_]{22})\/?$/i.test(
    value
  );
}

export { eventAddYtChannel };
