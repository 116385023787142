import {initOptinsList, getMlCollection} from "./optinsList";
import $ from "jquery";
import {eventAddExistingPl} from "../presave/app";

function initIntegrationStep() {

  $('#campaign_confirmation_email').on('select2:select', e => {
    const value = e.params.data.id;
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'confirmation_email',
        value
      },
      error() {
        M.toast({
          html: 'Error Saving',
          classes: 'error-toast'
        });
      },
      success() {
        M.toast({
          html: 'Saved',
          classes: 'success-toast'
        });
      }
    });
  });

  $('#campaign-step-reload .integrations-tab-header')
    .off()
    .on('click', function () {
      $('#campaign-step-reload .integrations-tab-header').removeClass('active');
      $(this).addClass('active');
      const showID = $(this).data('show');
      $('#campaign-step-reload .integrations-tab-container').removeClass(
        'active'
      );
      const $activeTabContainer = $(`#campaign-step-reload #${showID}`);
      $activeTabContainer.addClass('active');
      $(".fr-toolbar").hide();// hide toolbars of rich text editors (SMF-503)
      $activeTabContainer.find(".fr-toolbar").show();// show toolbars of rich text editors for active tab (SMF-503)
      const tab = $(this).data('tab');
      eventAddExistingPl();
      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'integrations_tab_memory',
          selected: tab
        }
      });
    });

  $('#campaign_apple_playlist_mode').on('select2:select', e => {
    const selectedID = e.params.data.id;
    const appleExistingPlaylist = $('#campaign-apple-existing');
    if (selectedID === 'library') {
      $('#campaign-apple-library').removeClass('hide');
      $('#campaign_apple_library_settings').removeClass('hide');
      // $('#campaign_apple_library_mode_options_settings').removeClass('hide');
      $('#campaign-apple-custom').addClass('hide');
      if ( ! ( appleExistingPlaylist.length > 0 && appleExistingPlaylist.hasClass('apple_presave_onepage') ) ) {
        $('#campaign-apple-existing').addClass('hide');
      } else {
        $('#campaign-apple-existing').removeClass('hide');
      }
    } else if (selectedID === 'custom') {
      $('#campaign-apple-library').addClass('hide');
      $('#campaign_apple_library_settings').addClass('hide');
      // $('#campaign_apple_library_mode_options_settings').addClass('hide');
      $('#campaign-apple-custom').removeClass('hide');
      $('#campaign-apple-existing').addClass('hide');
    } else if (selectedID === 'existing') {
      $('#campaign-apple-library').addClass('hide');
      $('#campaign_apple_library_settings').addClass('hide');
      // $('#campaign_apple_library_mode_options_settings').addClass('hide');
      $('#campaign-apple-custom').addClass('hide');
      $('#campaign-apple-existing').removeClass('hide');
    }
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'apple_mode_save',
        selected: selectedID
      }
    });
  });

  if ($("#opt-ins-block").length) {
    initOptinsList();
  }

}

function getMlCollections() {
  const mlCollections = {};

  if($("#ml-presave-page-table").length) {
    mlCollections.presave = getMlCollection('presave');
  }

  if($("#ml-form-page-table").length) {
    mlCollections.form = getMlCollection('form');
  }

  return mlCollections;
}

export {initIntegrationStep, getMlCollections};
