import uuidv4 from 'uuid/v4';
import { errorToast } from '../../../utils/toasts';

function eventAddAmazonPodcast() {
  const $amazonAdd = $('#amazon-add-podcast');
  if ($amazonAdd.length) {
    $amazonAdd.off().on('click', addPodcastAmazon);
    eventAmazonPodcastRemove();
  }
}

function addPodcastAmazon() {
  const totalPodcasts = $('.amazon-podcast-collection tbody tr').length;

  const $nameInput = $('#amazon-add-podcast-name');
  const $uriInput = $('#amazon-add-podcast-uri');
  const name = $nameInput.val();
  const uri = $uriInput.val();

  if (!validatePodcastURL(uri)) {
    errorToast('Invalid Amazon podcast URL');
    $uriInput.addClass('invalid');
    return;
  }

  if (name === '') {
    errorToast('Add a name for this Amazon podcast');
    $nameInput.addClass('invalid');
    return;
  }

  if (totalPodcasts >= 20) {
    errorToast('20 Amazon Podcasts Max');
    return;
  }

  const elemId = uuidv4();
  const element = `
  <tr id="${elemId}">
  <td class="edit-entry podcast-name">
    <input type="text" class="browser-default" name="amazon_podcast_follow[][name]" value="${name}">
  </td>
  <td class="edit-entry podcast-uri">
    <input type="text" class="browser-default" name="amazon_podcast_follow[][uri]" value="${uri}">
  </td>
  <td class="remove-field-amazon" data-removeid="${elemId}"><a><i class="material-icons">delete_forever</i></a></td>
  </tr>
  `;

  $('.amazon-podcast-collection tbody').append(element);
  $nameInput.val('');
  $uriInput.val('');
  $uriInput.removeClass('invalid');
  $nameInput.removeClass('invalid');

  reIndexFields();
}

function eventAmazonPodcastRemove() {
  $('.amazon-podcast-collection').on(
    'click',
    '.remove-field-amazon',
    removeAmazonPodcastElem
  );
}

function reIndexFields() {
  $(`.amazon-podcast-collection tbody tr`).each(function(index) {
    $(this)
      .find('.podcast-name input')
      .attr('name', `amazon_podcast_follow[${index}][name]`);
    $(this)
      .find('.podcast-uri input')
      .attr('name', `amazon_podcast_follow[${index}][uri]`);
  });
}

function removeAmazonPodcastElem() {
  const removeId = $(this).data('removeid');
  $(`#${removeId}`).remove();
  reIndexFields();
}

function validatePodcastURL(value) {
  return /^https:\/\/music.amazon.com\/podcasts\/[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}.*$/i.test(value);
}

export { eventAddAmazonPodcast };
