import forEach from './forEach';
/**
 * Materialize Tooltips
 * Called on AJAX reloading pages. It destroys and recreates all the
 * tooltips in the DOM. We need this because without it there ends up
 * being multiple instances of all the tooltips and it eventually kills
 * the page.
 */
export default function() {
  if ($('.material-tooltip').length) {
    const removeToolTips = document.querySelectorAll('.material-tooltip');
    forEach(removeToolTips, (index, value) => {
      $(value).remove();
    });
    const reToolTips = document.querySelectorAll('.tooltipped');
    forEach(reToolTips, (index, value) => {
      M.Tooltip.init(value);
    });
  } else {
    const initToolTips = document.querySelectorAll('.tooltipped');
    forEach(initToolTips, (index, value) => {
      M.Tooltip.init(value);
    });
  }
}
