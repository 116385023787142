/**
 * Remove fields
 *
 * @module utils/empty
 */

/**
 * Test for empty things and tries to play out most possible
 * data types
 * @param  {str,obj,arr,int} data Any data
 * @return {bool}                 False if empty
 */
export default function(data) {
  if (typeof data === 'number' || typeof data === 'boolean') {
    return false;
  }
  if (typeof data === 'undefined' || data === null) {
    return true;
  }
  if (typeof data.length !== 'undefined') {
    return data.length == 0;
  }
  let count = 0;
  for (const i in data) {
    if (data.hasOwnProperty(i)) {
      count++;
    }
  }
  return count == 0;
}
