import $ from "jquery";
import fixedEncodeURIComponent from "./fixedEncodeURIComponent";

export default function initGenresOptionsFormModal(e) {
  const $genresOptionsButton = $("#genres-options button");
  if ($genresOptionsButton.length) {
    $genresOptionsButton.off("click").on('click', genresOptionsFormModal);
  }
}

function genresOptionsFormModal(e) {
  e.preventDefault();
  const target = e.target.nextElementSibling;
  const modalContainer = document.querySelector(
    '#modal-form-genres'
  );
  M.Modal.init(modalContainer, {
    opacity: 0.8,
    dismissible: false,
    onOpenStart() {
      $('#my-select').multiSelect({
        selectableHeader: "<div class='custom-header'>Available</div>",
        selectionHeader: "<div class='custom-header'>Chosen</div>",
      })
    },
    onCloseEnd() {
      const selected = $("#my-select option:selected").map(function(){
        return {
          value: this.value,
          label: $(this).html()
        }
      }).get();
      target.value = fixedEncodeURIComponent(JSON.stringify(selected));

      const instance = M.Modal.getInstance(modalContainer);
      instance.destroy();
    }
  });
  const instance = M.Modal.getInstance(modalContainer);
  instance.open();
}
