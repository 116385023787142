/**
 * Remove fields
 *
 * @module utils/removeFieldInit
 */

/* global M */

/**
 * Remove Field Functionality
 */
export default function() {
  if ($('.remove-field').length) {
    const removeIcons = document.querySelectorAll('.remove-field');
    removeIcons.forEach((value) => {
      value.removeEventListener('click', removeField);
      value.addEventListener('click', removeField);
    });
  }
}

/**
 * Remove fields callback
 *
 * @param {*} e Event
 */
function removeField(e) {
  const elem = e.target.parentNode.parentNode;
  const fieldPublic = $(elem).data("public");
  const $availableCheckbox = $(`.available-fields input[data-public=${fieldPublic}]`);
  if ($availableCheckbox.length) {
    $availableCheckbox.prop("checked", false);
  }
  elem.remove();
  const toastHTML = 'Field Removed';
  M.toast({
    html: toastHTML,
    classes: 'success-toast'
  });
}
