import $ from "jquery";
import forEach from "./forEach";
import empty from "./empty";
import removeFieldInit from "./removeFieldInit";
import fixedEncodeURIComponent from "./fixedEncodeURIComponent";

export default function () {
  if ($('.add-options-container #custom-select-add-option').length) {
    const addNew = document.querySelector(
      '.add-options-container #custom-select-add-option'
    );
    addNew.addEventListener('click', addNewSelectOption);
  }

  if ($('.add-options-container #custom-radio-add-option').length) {
    const addNewRadio = document.querySelector(
      '.add-options-container #custom-radio-add-option'
    );
    addNewRadio.addEventListener('click', addNewRadioOption);
  }

  if ($('.add-options-container #custom-checkbox-add-option').length) {
    const addNewCheckbox = document.querySelector(
      '.add-options-container #custom-checkbox-add-option'
    );
    addNewCheckbox.addEventListener('click', addNewCheckboxOption);
  }
  if ($('#campaign-step-reload .enabled button').length) {
    const customFFOptions = document.querySelectorAll(
      '#campaign-step-reload .enabled button'
    );
    forEach(customFFOptions, (index, value) => {
      value.addEventListener('click', customOptionsModal);
    });
  }
  $('#custom-radio-add-option-label').keyup(() => {
    const text = $('#custom-radio-add-option-label').val();
    $('.spotify-copy').addClass('active');
    $('#custom-radio-add-option-value').val(text);
  });
  $('#custom-select-add-option-label').keyup(() => {
    const text = $('#custom-select-add-option-label').val();
    $('.spotify-copy').addClass('active');
    $('#custom-select-add-option-value').val(text);
  });
  $('#custom-checkbox-add-option-label').keyup(() => {
    const text = $('#custom-checkbox-add-option-label').val();
    $('.spotify-copy').addClass('active');
    $('#custom-checkbox-add-option-value').val(text);
  });
}

function customOptionsModal(e) {
  e.preventDefault();
  const type = e.target.getAttribute('data-type');
  const target = e.target.nextElementSibling;
  const availableTypes = {
    select: {id: "modal-form-custom-select"},
    radio: {id: "modal-form-custom-radio"},
    checkbox: {id: "modal-form-custom-checkbox"},
  };

  const containerId = availableTypes[type]['id'];

  const selectModalContainer = document.querySelector(
    `#${containerId}`
  );
  M.Modal.init(selectModalContainer, {
    opacity: 0.8,
    dismissible: false,
    onOpenStart() {
      const val = target.value;
      if (!empty(val)) {
        let obj = decodeURIComponent(val);
        obj = JSON.parse(obj);
        Object.keys(obj).forEach(key => {
          const { label, value } = obj[key];
          const element = `
            <td class="drag-handle"><i class="material-icons">drag_handle</i></td>
            <td class="edit-entry option-label"><input type="text" class="browser-default" value="${label}"></td>
            <td class="edit-entry option-value"><input type="text" class="browser-default" value="${value}"></td>
            <td class="remove-field"><i class="material-icons">delete_forever</i></td>
            `;
          const container = document.querySelector(
            `#${containerId} tbody`
          );
          const d = document.createElement('tr');
          d.innerHTML = element;
          container.appendChild(d);
        });
        removeFieldInit();
      }
    },
    onCloseEnd() {
      const ffOptContainer = {};
      const ffOptions = document.querySelectorAll(
        `#${containerId} tbody tr`
      );
      forEach(ffOptions, (index, value) => {
        const ffOpt = {};
        const labelItem = value.querySelector('.option-label input').value;
        const valueItem = value.querySelector('.option-value input').value;
        ffOpt.label = labelItem;
        ffOpt.value = valueItem;
        ffOptContainer[index] = ffOpt;
      });
      forEach(ffOptions, (index, value) => {
        value.remove();
      });
      let string = JSON.stringify(ffOptContainer);
      string = fixedEncodeURIComponent(string);
      target.value = string;
      const instance = M.Modal.getInstance(selectModalContainer);
      instance.destroy();
    }
  });
  const instance = M.Modal.getInstance(selectModalContainer);
  instance.open();
}

function addNewSelectOption() {
  const labelSet = document.querySelector('#custom-select-add-option-label')
    .value;
  const valueSet = document.querySelector('#custom-select-add-option-value')
    .value;
  const element = `
		<td class="drag-handle"><i class="material-icons">drag_handle</i></td>
		<td class="edit-entry option-label"><input type="text" class="browser-default" value="${labelSet}"></td>
		<td class="edit-entry option-value"><input type="text" class="browser-default" value="${valueSet}"></td>
		<td class="remove-field"><i class="material-icons">delete_forever</i></td>
		`;
  const container = document.querySelector('#modal-form-custom-select tbody');
  const d = document.createElement('tr');
  d.innerHTML = element;
  container.appendChild(d);
  document.querySelector('#custom-select-add-option-label').value = '';
  document.querySelector('#custom-select-add-option-value').value = '';
  removeFieldInit();
}

function addNewRadioOption() {
  const labelSet = document.querySelector('#custom-radio-add-option-label')
    .value;
  const valueSet = document.querySelector('#custom-radio-add-option-value')
    .value;
  const element = `
		<td class="drag-handle"><i class="material-icons">drag_handle</i></td>
		<td class="edit-entry option-label"><input type="text" class="browser-default" value="${labelSet}"></td>
		<td class="edit-entry option-value"><input type="text" class="browser-default" value="${valueSet}"></td>
		<td class="remove-field"><i class="material-icons">delete_forever</i></td>
		`;
  const container = document.querySelector('#modal-form-custom-radio tbody');
  const d = document.createElement('tr');
  d.innerHTML = element;
  container.appendChild(d);
  document.querySelector('#custom-radio-add-option-label').value = '';
  document.querySelector('#custom-radio-add-option-value').value = '';
  removeFieldInit();
}

function addNewCheckboxOption() {
  const labelSet = document.querySelector('#custom-checkbox-add-option-label')
    .value;
  const valueSet = document.querySelector('#custom-checkbox-add-option-value')
    .value;
  const element = `
		<td class="drag-handle"><i class="material-icons">drag_handle</i></td>
		<td class="edit-entry option-label"><input type="text" class="browser-default" value="${labelSet}"></td>
		<td class="edit-entry option-value"><input type="text" class="browser-default" value="${valueSet}"></td>
		<td class="remove-field"><i class="material-icons">delete_forever</i></td>
		`;
  const container = document.querySelector(
    '#modal-form-custom-checkbox tbody'
  );
  const d = document.createElement('tr');
  d.innerHTML = element;
  container.appendChild(d);
  document.querySelector('#custom-checkbox-add-option-label').value = '';
  document.querySelector('#custom-checkbox-add-option-value').value = '';
  removeFieldInit();
}
