import $ from "jquery";
import forEach from "./forEach";
import empty from "./empty";
import removeFieldInit from "./removeFieldInit";
import fixedEncodeURIComponent from "./fixedEncodeURIComponent";

export default function initGenresOptionsBuModal(e) {
  if ($('#custom-checkbox-add-option').length) {
    const addNewCheckbox = document.querySelector(
      '#custom-checkbox-add-option'
    );
    addNewCheckbox.addEventListener('click', addNewCheckboxOption);
  }
  const $genresOptionsButton = $("#genres-options button");
  if ($genresOptionsButton.length) {
    $genresOptionsButton.off("click").on('click', genresOptionsBuModal);
  }
  $('#custom-checkbox-add-option-label').keyup(() => {
    const text = $('#custom-checkbox-add-option-label').val();
    $('.spotify-copy').addClass('active');
    $('#custom-checkbox-add-option-value').val(text);
  });
}

function genresOptionsBuModal(e) {
  e.preventDefault();
  const target = e.target.nextElementSibling;
  const modalContainer = document.querySelector(
    '#modal-bu-genres'
  );
  M.Modal.init(modalContainer, {
    opacity: 0.8,
    dismissible: false,
    onOpenStart() {
      let val = target.value;
      if (empty(val)) {
        val = $("#default-genres-options").val();
      }

      let obj = decodeURIComponent(val);
      obj = JSON.parse(obj);
      const showEditFunc = $("#modal-bu-genres").hasClass('god-mode');
      Object.keys(obj).forEach(key => {
        const { label, value } = obj[key];

        let element = "";
        if (showEditFunc) {
          element += '<td class="drag-handle"><i class="material-icons">drag_handle</i></td>';
        }
        element += `
          <td class="edit-entry option-label"><input type="text" class="browser-default" value="${label}" ${(showEditFunc) ?  "" : 'disabled="disabled"'}></td>
          <td class="edit-entry option-value"><input type="text" class="browser-default" value="${value}" ${(showEditFunc) ?  "" : 'disabled="disabled"'}></td>
          `;
        if (showEditFunc) {
          element += '<td class="remove-field"><i class="material-icons">delete_forever</i></td>';
        }
        const container = document.querySelector(
          `#modal-bu-genres tbody`
        );
        const d = document.createElement('tr');
        d.innerHTML = element;
        container.appendChild(d);
      });
      removeFieldInit();

    },
    onCloseEnd() {
      const ffOptContainer = {};
      const ffOptions = document.querySelectorAll(
        `#modal-bu-genres tbody tr`
      );
      forEach(ffOptions, (index, value) => {
        const ffOpt = {};
        const labelItem = value.querySelector('.option-label input').value;
        const valueItem = value.querySelector('.option-value input').value;
        ffOpt.label = labelItem;
        ffOpt.value = valueItem;
        ffOptContainer[index] = ffOpt;
      });
      forEach(ffOptions, (index, value) => {
        value.remove();
      });
      let string = JSON.stringify(ffOptContainer);
      string = fixedEncodeURIComponent(string);
      target.value = string;
      const instance = M.Modal.getInstance(modalContainer);
      instance.destroy();
    }
  });
  const instance = M.Modal.getInstance(modalContainer);
  instance.open();
}

function addNewCheckboxOption() {
  const labelSet = document.querySelector('#custom-checkbox-add-option-label').value;
  const valueSet = document.querySelector('#custom-checkbox-add-option-value').value;
  const element = `
		<td class="drag-handle"><i class="material-icons">drag_handle</i></td>
		<td class="edit-entry option-label"><input type="text" class="browser-default" value="${labelSet}"></td>
		<td class="edit-entry option-value"><input type="text" class="browser-default" value="${valueSet}"></td>
		<td class="remove-field"><i class="material-icons">delete_forever</i></td>
		`;
  const container = document.querySelector(
    '#modal-bu-genres tbody'
  );
  const d = document.createElement('tr');
  d.innerHTML = element;
  container.appendChild(d);
  document.querySelector('#custom-checkbox-add-option-label').value = '';
  document.querySelector('#custom-checkbox-add-option-value').value = '';
  removeFieldInit();
}
